import React from "react";
import Helmet from "react-helmet";

import Header from "./app-header";
import Footer from "./app-footer";
import Seo from "../components/seo";
import CollapsableCardScript from "../scripts/collapsable-cards";
import { PreviewBanner, LoadingBox, usePreview } from "./preview";
import ClickableTabs from "../scripts/clickable-tabs";
import LightboxSlider from "../scripts/about-slider-lightbox";
import YouTubeLightbox from "../scripts/about-youtube-lightbox";

export default function Template(props) {
  const { data } = props.pageContext;
  const { pageData, isLoading } = usePreview(data);

  // handling headers
  const metaTags = [];
  const links = [];

  const removeType = (tag) => {
    let newTag = { ...tag };
    delete newTag.type;
    return newTag;
  };
  let parsedHeader = [];
  try {
    parsedHeader = JSON.parse(data.headerArr) || [];
  } catch (error) {
    console.log(error);
    console.log(
      `ERROR: Page - ${pageData.slug} failed during build due to above error. Please check the meta tags of this page on CMS.`
    );
    parsedHeader = [];
  }
  parsedHeader.forEach((tag) => {
    if (tag.type === "link") {
      links.push(removeType(tag));
    } else {
      metaTags.push(tag);
    }
  });
  return (
    <div>
      <div>
        <PreviewBanner />
      </div>
      <div className="header">
        <Header />
      </div>
      <div className="main">
        <div className="page-content">
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: pageData.body }} />
              <Helmet
                title={pageData.title}
                meta={metaTags || []}
                link={links || []}
              />
              <Seo />
            </>
          )}
        </div>
      </div>

      <Footer />

      <CollapsableCardScript />
      <ClickableTabs />
      <LightboxSlider />
      <YouTubeLightbox />
    </div>
  );
}
