import React, { useEffect } from "react";

const YouTubeLightbox = () => {
  useEffect(() => {
    const initializeYouTubePlayer = () => {
      const thumbnail = document.getElementById("thumbnail");

      // Load YouTube IFrame API
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      let player; // Declare player variable in the useEffect scope

      const openLightbox = () => {
        // Create lightbox HTML structure
        const lightbox = document.createElement("div");
        lightbox.id = "videolightbox";
        lightbox.classList.add("lightbox");
        lightbox.innerHTML = `
          <span class="close" id="closeLightbox">&times;</span>
          <div class="lightbox-content">
            <div class="responsive-video">
              <div id="videoContainer">
                <iframe
                  width="850"
                  height="480"
                  src="https://www.youtube.com/embed/HWtWvtEKlFw?enablejsapi=1&autoplay=1"
                  frameborder="0"
                  allowfullscreen
                  id="video"
                ></iframe>
              </div>
            </div>
          </div>
        `;
        document.body.appendChild(lightbox);

        // Add event listeners for closing the lightbox
        const closeButton = document.getElementById("closeLightbox");
        closeButton?.addEventListener("click", () => {
          lightbox.remove();
          if (player && player.stopVideo) {
            player.stopVideo();
          }
        });

        window.addEventListener("click", (event) => {
          if (event.target === lightbox) {
            lightbox.remove();
            if (player && player.stopVideo) {
              player.stopVideo();
            }
          }
        });

        // Ensure the YouTube API is loaded before initializing the player
        if (window.YT && window.YT.Player) {
          player = new window.YT.Player("video", {
            events: {
              onReady: (event) => {
                event.target.playVideo();
              },
            },
          });
        }
      };

      // Check if the YouTube API is ready
      if (window.YT && window.YT.Player) {
        thumbnail?.addEventListener("click", openLightbox);
      } else {
        window.onYouTubeIframeAPIReady = () => {
          thumbnail?.addEventListener("click", openLightbox);
        };
      }
    };

    // Initialize the YouTube player script
    if (typeof window !== "undefined") {
      initializeYouTubePlayer();
    }
  }, []);

  return <></>;
};

export default YouTubeLightbox;
