import React, { useEffect } from "react";

const ClickableTabs = () => {
  useEffect(() => {
    if (window) {
      window["openVideoSecurityTab"] = (clickedTab, isPiracyTool = false) => {
        if (isPiracyTool)
          document
            .querySelector(".tab-container")
            ?.scrollIntoView({ behavior: "smooth", block: "start" });

        // Remove and Add active class to Tabs
        document
          .querySelector(".tab-button-active")
          ?.classList?.remove("tab-button-active");
        document
          .getElementById(clickedTab)
          ?.classList?.add("tab-button-active");
        // Remove and Add active class to Tabs Contents
        document
          .querySelector(".tab-inside-active")
          ?.classList?.remove("tab-inside-active");
        document
          .querySelector(`.tab-inside.${clickedTab}`)
          ?.classList?.add("tab-inside-active");
      };
    }
  }, []);
  return <div className="script"></div>;
};
export default ClickableTabs;
