import React, { useState, useCallback, useEffect } from "react";

const LightboxSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cardsToShow = 3;
  const images = [
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-Kasauli-trip1.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-kerala-trip2.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-kerala-trip3.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-outing-trip4.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-meeting-room01.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-stall-7.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-stall-5.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-meeting-room02.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-stall-6.jpg",
    "https://www.vdocipher.com/blog/wp-content/uploads/2024/07/vdocipher-edtechreview7.jpg",
  ];

  const updateArrows = useCallback(() => {
    const sliderRef = document.querySelector(".slider");
    if (!sliderRef) return;

    const arrowLeft = document.querySelector(".arrow-left");
    const arrowRight = document.querySelector(".arrow-right");
    const cards = Array.from(document.querySelectorAll(".slider-card"));
    const currentIndex =
      parseInt(sliderRef.getAttribute("data-current-index"), 10) || 0;

    if (arrowLeft && arrowRight) {
      arrowLeft.classList.toggle("disabled", currentIndex === 0);
      arrowRight.classList.toggle(
        "disabled",
        currentIndex >= cards.length - cardsToShow
      );
    }
  }, [cardsToShow]);

  const showCard = useCallback(
    (index) => {
      const sliderRef = document.querySelector(".slider");
      if (!sliderRef) return;

      const cardWidth = sliderRef.children[0]?.offsetWidth + 2; // Include margin
      const slideDistance = -index * cardWidth;
      sliderRef.style.transform = `translateX(${slideDistance}px)`;
      sliderRef.setAttribute("data-current-index", index);
      updateArrows();
    },
    [updateArrows]
  );

  const handleNext = useCallback(() => {
    const sliderRef = document.querySelector(".slider");
    if (!sliderRef) return;

    const cards = Array.from(document.querySelectorAll(".slider-card"));
    let currentIndex =
      parseInt(sliderRef.getAttribute("data-current-index"), 10) || 0;
    if (currentIndex < cards.length - cardsToShow) {
      currentIndex++;
      showCard(currentIndex);
    }
  }, [showCard, cardsToShow]);

  const handlePrev = useCallback(() => {
    const sliderRef = document.querySelector(".slider");
    if (!sliderRef) return;

    let currentIndex =
      parseInt(sliderRef.getAttribute("data-current-index"), 10) || 0;
    if (currentIndex > 0) {
      currentIndex--;
      showCard(currentIndex);
    }
  }, [showCard]);

  const openLightbox = (index) => {
    const lightbox = document.getElementById("lightbox");
    const lightboxImage = document.querySelector("#lightbox img");
    lightboxImage.src = images[index];
    lightbox.style.display = "flex";
    setCurrentIndex(index);
  };

  const closeLightbox = () => {
    const lightbox = document.getElementById("lightbox");
    lightbox.style.display = "none";
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    const lightboxImage = document.querySelector("#lightbox img");
    lightboxImage.src = images[nextIndex];
    setCurrentIndex(nextIndex);
  };

  const showPrevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    const lightboxImage = document.querySelector("#lightbox img");
    lightboxImage.src = images[prevIndex];
    setCurrentIndex(prevIndex);
  };

  useEffect(() => {
    showCard(0);
    updateArrows();

    const arrowLeft = document.querySelector(".arrow-left");
    const arrowRight = document.querySelector(".arrow-right");

    if (arrowLeft && arrowRight) {
      arrowLeft.addEventListener("click", handlePrev);
      arrowRight.addEventListener("click", handleNext);

      return () => {
        arrowLeft.removeEventListener("click", handlePrev);
        arrowRight.removeEventListener("click", handleNext);
      };
    }
  }, [handlePrev, handleNext, showCard, updateArrows]);

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(autoSlideInterval); // Cleanup on component unmount
  }, [handleNext]);

  // Attach functions to window object
  useEffect(() => {
    window.openLightbox = openLightbox;
    window.closeLightbox = closeLightbox;
    window.showNextImage = showNextImage;
    window.showPrevImage = showPrevImage;
    window.handleNext = handleNext;
    window.handlePrev = handlePrev;
    window.showCard = showCard;
    window.updateArrows = updateArrows;
  }, [
    openLightbox,
    closeLightbox,
    showNextImage,
    showPrevImage,
    handleNext,
    handlePrev,
    showCard,
    updateArrows,
  ]);

  return <></>;
};

export default LightboxSlider;
